const translations = {
  chat: {
    poweredBy: 'Powered by',
    conversation: {
      followInstructions:
        'Follow {{- name, possessive}} instructions or you can ask AI a question',
      awaitingUserMessage:
        'is waiting for your response or you can ask AI a question',
      awaitingPR:
        'is waiting for you to handle the PR or you can ask AI a question',
      awaitingChatForm:
        'waiting for your submission or you can ask AI a question',
      connectToGithub: 'Connect to Github',
      connectToWix: 'Connect to Wix',
      stuck: "🙋 Stuck? Don't hesitate,",
      contactSupport: 'Contact support',
      isTyping: 'is typing',
      marker: {
        questStarted: 'Started',
        questFinished: 'Finished',
        questStopped: 'Stopped',
        questContinuing: 'Continuing',
        newMessage: 'New',
      },
      forms: {
        multiSelect: {
          selected: `Selected: {{selected}}`,
        },
        singleSelect: {
          selected: `Selected: {{selected}}`,
        },
        secretInput: {
          empty: 'Can not be empty',
        },
      },
      message: {
        new: 'New',
        aiGenerated: 'AI Generated Responses',
        ts: 'h:mm A',
        tsNoMeridiem: 'h:mm',
        tsFormat: 'MMM Do [at] LT',
      },
      getHint: 'Request a hint',
      getSolution: 'Request a solution',
    },
    header: {
      elapsedTime: {
        tooltip:
          'The time elapsed since the quest began. It turns red if it exceeds the average time',
      },
      notVerifiedQuest: 'This quest was not verified by Wilco',
    },
    notifications: {
      achievement: 'Achievement earned',
    },
    sidebar: {
      backToWilco: 'Back to Wilco',
      signup: 'Sign-up to Wilco',
      replayQuest: 'Start over quest',
      wilcoChat: 'Wilco Chat',
      codespace: {
        title: 'Codespace',
        text: 'Codespace',
        statusText: {
          online: 'Online',
          idle: 'Idle',
          offline: 'Offline',
        },
        tooltip:
          'Codespaces is a cloud-based development environment where you can complete Wilco quests without installing anything on your local machines.',
      },
      kubernetes: {
        title: 'Kubernetes',
        download: 'Download Kubeconfig',
      },
      reportIssue: {
        text: 'Report Quest',
        subject: 'Reporting issue in quest: {{primaryId}} id: {{id}}',
        body: 'Hi,\n\nI would like to report an issue with the quest {{primaryId}} id: {{id}}',
      },
      userTools: {
        title: 'Tools',
        github: 'GitHub Repository',
        newRelic: 'New Relic Instance',
        mongoAtlas: 'MongoDB Atlas Instance',
        circleCi: 'CircleCI Instance',
        permit: 'Permit.io Instance',
      },
      friends: {
        title: 'Direct messages',
      },
      help: {
        title: 'Help',
      },
    },
    questInfoBar: {
      about: 'About the quest',
      timeEstimate: 'Time Estimation',
      hours: 'Hours',
      hour: 'Hour',
      step: 'Step',
      questProgress: {
        heading: 'Quest Progress',
        completed: 'Completed',
      },
    },
  },
  modals: {
    replay: {
      header: 'Start Over Quest',
      switch:
        'If you choose to change the current quest, your current quest progress will not be saved.\nTo return to this quest you will need to start it over.\nDo you want to?',
      restart:
        'If you choose to replay or restart this quest, you will not earn any wilcoins and achievements you already earned in this quest.',
      button: 'Start Over',
    },
  },
};

export default translations;
